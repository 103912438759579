import React, { useContext, useState } from 'react'
import Context from '../../Context/Context'
import { useNavigate } from 'react-router-dom'
import web from '../../Utils/Data/data.json'

import {
  FaUser,
  FaCalendarAlt,
  FaHistory,
  FaUsers,
  FaVideo,
  FaEdit,
  FaEnvelope
} from 'react-icons/fa'
import { FaBuildingColumns } from 'react-icons/fa6'

const LeftBanner = ({ displayAfterClick }) => {
  const [click, setClick] = useState('Upcoming Classes')
  const UserCtx = useContext(Context)
  const Navigate = useNavigate()
  const isMember = UserCtx.userData.userType === 'member'
  const isAdmin = UserCtx.userData.userType === 'admin'
  const isInstructor = UserCtx.userData.userType === 'instructor'

  const getInitials = (name) => {
    if (!name) return ''
    const initials = name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase())
      .join('')
    return initials
  }

  const getColor = (name) => {
    if (!name) return '#888888'
    const colors = [
      '#FF5733',
      '#33FF57',
      '#5733FF',
      '#FF5733',
      '#33FF57',
      '#5733FF',
      '#FF5733',
      '#33FF57',
      '#5733FF',
      '#FF5733',
      '#33FF57',
      '#5733FF'
    ]
    const index = name.length % colors.length
    return colors[index]
  }

  const ListItem = ({ icon, text, onClickFn }) => {
    return (
      <div
        className={`w-[90%] px-2 flex flex-row items-center rounded-md hover:bg-slate-700 cursor-pointer ${click === text && 'bg-slate-700 font-medium'}`}
        onClick={onClickFn}
      >
        <span className="px-2">{icon}</span>
        <li
          className={`px-1 py-2 ${click === text ? `font-semibold` : `font-medium`}`}
        >
          {text}
        </li>
      </div>
    )
  }

  return (
    <div className="bg-[#1F2A37] text-[#E4E4E4] w-[17.5rem] h-[88vh] mt-2 relative">
      <nav>
        <div className="h-auto flex items-center gap-3 px-4 py-3">
          {UserCtx.userData.imgUrl ? (
            <img
              className="h-12 w-12 rounded-full bg-white"
              src={UserCtx.userData.imgUrl}
              alt="profile"
              srcset=""
            />
          ) : (
            <div
              className={`rounded-full p-2 bg-gray-600 flex items-center justify-center text-[1rem] text-white`}
              style={{ backgroundColor: getColor(UserCtx.userData.userName) }}
            >
              {getInitials(UserCtx.userData.userName)}
            </div>
          )}

          <p className="m-0 p-0 text-lg font-semibold">
            Hi👋, {UserCtx.userData.userName?.split(' ')[0]}
          </p>
        </div>
        <span className="h-[1px] w-full bg-slate-700 block mb-3"></span>
        <ul className="flex flex-col gap-2 justify-center ml-0 list-none pl-0 items-center">
          <ListItem
            icon={<FaUser size={18} />}
            text="Profile"
            onClickFn={() => {
              setClick('Profile')
              displayAfterClick(3)
            }}
          />
          <ListItem
            icon={<FaCalendarAlt size={18} />}
            text="Upcoming Classes"
            onClickFn={() => {
              setClick('Upcoming Classes')
              displayAfterClick(0)
            }}
          />
          <ListItem
            icon={<FaHistory size={18} />}
            text="Previous Classes"
            onClickFn={() => {
              setClick('Previous Classes')
              displayAfterClick(1)
            }}
          />
          <span className="h-[1px] w-full bg-slate-700 block mt-3 mb-3"></span>
          {isAdmin && (
            <ListItem
              icon={<FaUsers size={20} />}
              text="Members"
              onClickFn={() => {
                setClick('Members')
                displayAfterClick(4)
              }}
            />
          )}
          <ListItem
            icon={<FaVideo size={18} />}
            text="Video"
            onClickFn={() => {
              setClick('Video')
              displayAfterClick(2)
            }}
          />
          <div
            className={`w-[90%] px-2 flex flex-row items-center rounded-md hover:bg-slate-700 cursor-pointer ${click === 'Ratings' && 'bg-slate-700'}`}
            onClick={() => {
              setClick('Ratings')
              displayAfterClick(5)
            }}
          >
            <span>
              <img
                className="h-7 w-7 mx-1"
                src="https://institution-utils.s3.us-east-1.amazonaws.com/institution-common/Assests/rate.png"
                alt='ratings'
              />
            </span>
            <li className="px-1 py-2 font-medium">Ratings</li>
          </div>
          {!isInstructor && (
            <span className="h-[1px] w-full bg-slate-700 block mt-3 mb-3"></span>
          )}
          {isAdmin && (
            <ListItem
              icon={<FaEdit size={18} />}
              text="Products"
              onClickFn={() => {
                const baseUrl =
                  process.env.REACT_APP_STAGE === 'PROD'
                    ? 'http://happyprancer.com'
                    : 'http://beta.happyprancer.com'

                const url = `${baseUrl}/allpayment/${web.InstitutionId}/${UserCtx.userData.cognitoId}/${UserCtx.userData.emailId}`
                window.open(url, '_blank', 'noopener,noreferrer')
              }}
            />
          )}
          {!isInstructor && (
            <ListItem
              icon={<FaBuildingColumns size={18} />}
              text={isAdmin ? 'Revenue' : 'Payments'}
              onClickFn={() => {
                isMember ? setClick('Payments') : setClick('Revenue')
                isMember ? (displayAfterClick(6)
              ) : displayAfterClick(7)
              }}
            />
          )}
          <div
            className="w-full flex flex-row pl-5 mt-[1.9rem] items-center bg-[#202020] hover:bg-slate-700 cursor-pointer rounded-t-lg absolute bottom-0"
            onClick={() => Navigate('/query')}
          >
            <span className="px-2">
              <FaEnvelope size={18} />
            </span>
            <li className="pr-6 pl-1 py-2 font-medium">Contact us ?</li>
          </div>
        </ul>
      </nav>
    </div>
  )
}

export default LeftBanner
