import { useState, useEffect } from 'react';
import Context from './InstitutionContext';
import { API } from 'aws-amplify';

const formatTime = (date) => {
  return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
};

const extractCommonTimes = (classes) => {
  const commonTimes = new Set();
  classes.forEach((classInfo) => {
    const date = new Date(classInfo.startTime);
    const startTime = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
    commonTimes.add(startTime);
  });
  return Array.from(commonTimes).sort();
};

const generateTimeSlotList = (nextWeekClasses) => {
  const timeSlotList = {};
  nextWeekClasses.forEach(classInfo => {
    const date = new Date(classInfo.startTime);
    const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
    const startTime = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });

    if (!timeSlotList[startTime]) {
      timeSlotList[startTime] = {};
    }

    if (!timeSlotList[startTime][dayOfWeek]) {
      timeSlotList[startTime][dayOfWeek] = [];
    }

    timeSlotList[startTime][dayOfWeek].push(classInfo);
  });

  return timeSlotList;
};

const InstitutionContextProvider = (props) => {
  const [institutionData, setInstitutionData] = useState(null);
  const [scheduleData, setScheduleData] = useState(null);

  useEffect(() => {
    const fetchSchedule = async () => {
      if (institutionData) {
        try {
          const institutionId = institutionData.InstitutionId;
          const response = await API.get('user', `/user/schedule/${institutionId}`);
          const currentDate = new Date();
          const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
          const nextWeekEndDate = new Date(today);
          nextWeekEndDate.setDate(nextWeekEndDate.getDate() + 7);

          const nextWeekClasses = response.filter(classInfo => {
            const classDate = new Date(classInfo.startTime);
            return classDate >= today && classDate < nextWeekEndDate;
          });

          if (nextWeekClasses.length > 0) {
            const groupedSchedule = groupScheduleByDay(nextWeekClasses);
            const commonTimes = extractCommonTimes(nextWeekClasses);
            const timeSlotList = generateTimeSlotList(nextWeekClasses);
            const schedule = { groupedSchedule, commonTimes, timeSlotList };
            setScheduleData(schedule);
            localStorage.setItem('scheduleData', JSON.stringify(schedule));
          } else {
            setScheduleData(null);
            localStorage.setItem('scheduleData', null);
          }
        } catch (error) {
          console.error('Error fetching schedule:', error);
        }
      }
    };
    
    const groupScheduleByDay = (scheduleData) => {
      const groupedSchedule = {};
      scheduleData.forEach((classInfo) => {
        const date = new Date(classInfo.startTime);
        const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
        const startTime = formatTime(date);
        const track = classInfo.track || '';
        if (!groupedSchedule[dayOfWeek]) {
          groupedSchedule[dayOfWeek] = {};
        }
        if (!groupedSchedule[dayOfWeek][track]) {
          groupedSchedule[dayOfWeek][track] = [];
        }
        groupedSchedule[dayOfWeek][track].push({ ...classInfo, startTime });
      });
      return groupedSchedule;
    };

    fetchSchedule();
  }, [institutionData]);

  const setInstitutionDataFn = (data) => {
    setInstitutionData(data);
  };

  const ContextData = {
    institutionData: institutionData,
    setInstitutionData: setInstitutionDataFn,
    scheduleData: scheduleData,
  };

  return (
    <Context.Provider value={ContextData}>{props.children}</Context.Provider>
  );
};

export { InstitutionContextProvider };
