import React, { useContext, useState, useEffect } from 'react'
import { Auth, API } from 'aws-amplify'
import NavBar from '../Components/NavBar'
import Context from '../Context/Context'
import { useLocation, useNavigate } from 'react-router-dom'
//import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Country from '../Components/Auth/Country'
import InstitutionContext from '../Context/InstitutionContext'
import { toast } from 'react-toastify'

const SignUp = () => {
  const InstitutionData = useContext(InstitutionContext).institutionData
  const [firstName, setFirstName] = useState('') // Added for first name
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState('91')
  const [country, setCountry] = useState('India')
  //  const [password, setPassword] = useState("");
  //  const [confirmPassword, setConfirmPassword] = useState("");
  const [newUser, setNewUser] = useState(false)
  const [confirmationCode, setConfirmationCode] = useState(0)
  const [err, setErr] = useState('')
  //  const [passwordVisible, setPasswordVisible] = useState(false);
  //  const [isNewUser, setIsNewUser] = useState(true)
  const [signinResponse, setSigninResponse] = useState(null)

  const data = {
    Otp_Msg: `An OTP has been sent to +${countryCode}${phoneNumber}. Please check your messages for the OTP.`,
  };
  const UtilCtx = useContext(Context).util
  const UserCtx = useContext(Context)
  const Navigate = useNavigate()
  const location = useLocation();

  const [counter, setCounter] = useState(60) // Timer counter
  const [resendVisible, setResendVisible] = useState(false) // Resend OTP visibility
  const [redirectPage, setRedirectPage] = useState(null);
  const [referred_code, setReferral_code] = useState(localStorage.getItem('referralCode') || '');

  //redirect page logic
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const redirect = params.get('redirect');
    const referral = params.get('referral');
    if (redirect) {
      setRedirectPage(redirect);
    }
    if (referral) {
      setReferral_code(referral)
      localStorage.setItem('referralCode', referral);
    }

    let timer = null
    if (counter > 0) {
      timer = setInterval(
        () => setCounter((prevCounter) => prevCounter - 1),
        1000
      )
    } else {
      setResendVisible(true)
    }

    return () => {
      clearInterval(timer)
    }
  }, [counter, location.search]);

  //  const passwordVisibilityChange = () => {
  //    setPasswordVisible((prevState) => !prevState);
  //  };

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          if (redirectPage) {
            Navigate(`/${redirectPage}`);
          } else {
            Navigate('/dashboard');
          }
        }
      } catch (error) {
        console.error('Error checking authentication status:', error);
      }
    };

    checkAuthStatus();
  }, [redirectPage, Navigate]);

  const form1Validator = () => {
    if (firstName.length === 0) {
      setErr('Enter the Name')
      return false
    } else if (phoneNumber.length < 10) {
      setErr('Enter a Valid Phone Number')
      return false
    } else if (countryCode.length === 0) {
      setErr('Choose Country')
      return false
    } else if (!(email.includes('@') && email.includes('.'))) {
      setErr('Enter a Valid Email')
      return false
    } else if (country.length === 0) {
      setErr('Enter a Country Name')
      return false
    } else {
      setErr('')
      return true
    }
  }

  const form2Validator = () => {
    if (confirmationCode.length === 0) {
      setErr('Enter a Valid Code')
      return false
    } else {
      setErr('')
      return true
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    UtilCtx.setLoader(true)

    try {
      if (form1Validator()) {
        //        if (!isNewUser) {
        //          await userExistPhoneNumberSignUp()
        //          UtilCtx.setLoader(false)
        //          return
        //        }
        // TRY TO SIGNUP
        try {
          await Auth.signUp({
            username: `+${countryCode}${phoneNumber}`,
            // username: email,
            password: 'Password@123',
            attributes: {
              phone_number: `+${countryCode}${phoneNumber}`,
              name: `${firstName} ${lastName}`,
              email: email
            }
          })
        }
        catch (e) {
          console.error(e);
        }
        // EVEN IF THERE IS AN ERROR, TRY TO SIGNIN
        finally {
          const phoneResponse = await API.post(
            'user',
            `/any/phone-exists/${InstitutionData.InstitutionId}`,
            {
              body: {
                phoneNumber: `+${countryCode}${phoneNumber}`
              }
            }
          )

          if (phoneResponse.exists === true) {
            toast.warn('An account with this phone number already exists. Please login or signup with a different number.')
          }
          else {
            const response = await Auth.signIn(`+${countryCode}${phoneNumber}`)
            console.log(response)
            setSigninResponse(response)
            setNewUser(true)
          }
        }
      }
      UtilCtx.setLoader(false)
    } catch (e) {
      //      if (e.message === 'User already exists') {
      //        await sendOTP()
      //        UtilCtx.setLoader(false)
      //        return
      //      }
      console.log(e)
      setErr(e.message)
      UtilCtx.setLoader(false)
    }
  }

  const onConfirmationSubmit = async (event) => {
    event.preventDefault()

    UtilCtx.setLoader(true)

    try {
      if (form2Validator()) {
        //        await Auth.confirmSignUp(
        //          `+${countryCode}${phoneNumber}`,
        //          // email,
        //          confirmationCode
        //        );
        //        await Auth.signIn(`+${countryCode}${phoneNumber}`);
        // await Auth.signIn(email, password);

        await Auth.sendCustomChallengeAnswer(
          signinResponse,
          `${confirmationCode}`
        )
        await Auth.currentSession()
        await API.post(
          'user',
          `/user/profile/${InstitutionData.InstitutionId}`,
          {
            body: {
              emailId: email,
              userName: `${firstName} ${lastName}`,
              phoneNumber: `+${countryCode}${phoneNumber}`,
              country: country,
              referred_code: referred_code
            }
          }
        )
        const userdata = await API.get(
          'user',
          `/user/profile/${InstitutionData.InstitutionId}`
        )

        UserCtx.onAuthLoad(true, InstitutionData.InstitutionId)
        //Temporary
        // userdata.Status = true;
        UserCtx.setUserData(userdata)
        UserCtx.setIsAuth(true)
        UtilCtx.setLoader(false)
        toast.info('Signed Up')

        localStorage.removeItem('referralCode');
        // Check for the source parameter and redirect accordingly
        if (redirectPage) {
          Navigate(`/${redirectPage}`);
        } else {
          if (userdata.status === 'Active') {
            Navigate('/dashboard');
          } else {
            Navigate('/subscription');
          }
        }
      } else {
        UtilCtx.setLoader(false);
      }
      UtilCtx.setLoader(false);
    } catch (e) {

      console.log(e)
      if (e === 'No current user') {
        setErr('Incorrect OTP. Try again.')
      }
      else if (e.message === 'Invalid session for the user.' || e.message === 'Incorrect username or password.') {
        toast.error('OTP expired. Please signup again.')
        setNewUser(false)
      }
      else {
        setErr(e.message)
      }
      UtilCtx.setLoader(false)
    }
  }

  const handleSignInRedirect = () => {
    if (redirectPage) {
      Navigate(`/login?redirect=${redirectPage}`);
    } else {
      Navigate('/login');
    }
  };

  const form1 = () => {
    return (
      <form
        className={`w-[50vw] max800:w-[90vw] max-w-[29rem] bg-[#FFFFFF] shadow-2xl rounded-2xl p-4 flex flex-col items-center mt-[-2.7rem] `}
      >
        <h3 className={`text-[1.1rem] font-[700]`}>Sign Up</h3>
        <div className={`text-center`}>
          <p className={`w-[13.5rem] font-[500] my-2 text-[0.8rem]`}>
            Unlock Your Potential, Sign Up Today for a Healthier Tomorrow!
          </p>
        </div>
        <ul className={`flex flex-col items-center px-0`}>
          <li className={`flex items-center mt-8 max500:flex-col max500:items-start`}>
            <div className="relative">
              <ValidatorForm>
                <div className="flex flex-row space-x-6">
                  <div>
                    <TextValidator
                      label={<span className='flex ml-2'>First Name</span>}
                      className={`border-[2px]  rounded-2`}
                      style={{
                        width: '9rem',
                        height: '2.5rem',
                      }}
                      variant="outlined"
                      size="small"
                      type="text"
                      validators={['required']}
                      errorMessages={['This field is required', 'First name cannot exceed 15 characters']}
                      value={firstName}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue.length <= 15) {
                          setFirstName(inputValue);
                        }
                      }}
                    />
                  </div>
                  <div>
                    <TextValidator
                      label={<span className='flex ml-2'>Last Name</span>}
                      className={`border-[2px] rounded-2`}
                      style={{
                        width: '9rem',
                        height: '2.5rem',
                      }}
                      variant="outlined"
                      size="small"
                      type="text"
                      validators={['required']}
                      errorMessages={['This field is required', 'Last name cannot exceed 15 characters', 'Only alphabets are allowed']}
                      value={lastName}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const regex = /^[A-Za-z]*$/; // Regex to allow only alphabets
                        if (inputValue.length <= 15 && regex.test(inputValue)) {
                          setLastName(inputValue);
                        }
                      }}
                    />
                  </div>
                </div>
              </ValidatorForm>
            </div>
          </li>
          <li className={`flex gap-20 mt-8  max500:flex-col max500:gap-2 max500:items-start relative`}>
            <ValidatorForm >
              <div className="relative">
                <TextValidator
                  label={<span className='flex mt-2 ml-2'>Email</span>}
                  className={`w-[19.5rem] border-[2px] px-6 py-2 rounded-2 max500:w-[80vw]`}
                  variant="outlined"
                  size="small"
                  type="email"
                  validators={['required', 'isEmail']}
                  errorMessages={['This field is required', 'Email is not valid']}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </ValidatorForm>
          </li>
          <li className={`flex gap-20 mt-2 ml-6 max500:flex-col max500:gap-2 max500:items-start relative`}>
            <select
              value={countryCode}
              name="countryCode"
              id=""
              className={`w-[19.5rem] mr-[1.5rem] border-[2px] px-[1.5rem] py-2 rounded-2 max500:w-[80vw] mt-6`}
              onChange={(e) => {
                const index = e.target.options.selectedIndex;
                const text = e.target.options[index].text;
                setCountry(text.split(' ')[0]);
                setCountryCode(e.target.value.toString());
              }}
            >
              {<Country />}
            </select>
          </li>
          <li className={`flex gap-20 mt-8 max500:flex-col max500:gap-2 max500:items-start relative`}>
            <div className="relative mb-6">
              <ValidatorForm onSubmit={onSubmit}>
                <TextValidator
                  label={<span className='flex mt-2 ml-2'>Enter Phone</span>}
                  className={`border-[2px] px-6 py-2 rounded-2 responsive-width`}
                  style={{
                    width: '19.5rem',
                    height: '1.5rem'
                  }}
                  variant="outlined"
                  size="small"
                  type="text"
                  validators={['required']}
                  errorMessages={['This field is required', 'Please enter a valid phone number']}
                  value={phoneNumber}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue) && inputValue.length <= 10) {
                      setPhoneNumber(inputValue);
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      onSubmit(e);
                    }
                  }}
                />
              </ValidatorForm>
            </div>
          </li>
          <li className={`flex gap-20 mt-7 max500:flex-col max500:gap-2 max500:items-start relative mb-4`}>
            <div className={`relative`}>
              <ValidatorForm onSubmit={onSubmit}>
                <TextValidator
                  label={<span className='flex mt-2 ml-2'>Referral Code</span>}
                  variant="outlined"
                  name="referralCode"
                  size="small"
                  type="text"
                  value={referred_code}
                  onChange={(e) => {
                    setReferral_code(e.target.value);
                  }}
                  className={`border-[2px] px-6 py-2 rounded-2 responsive-width`}
                  style={{
                    width: '19.5rem',
                    height: '1.5rem'
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      onSubmit(e);
                    }
                  }}
                />
              </ValidatorForm>
            </div>

            <style jsx>{`
              @media (max-width: 500px) {
              .responsive-width {
              width: 80vw !important;
                    }
                  }
              `}</style>
          </li>
        </ul>
        {err && <p className={`text-[0.8rem]  mt-2 text-red-500`}>{err}</p>}
        <button
          className={`w-[19.5rem] py-2 mt-3 mb-3 text-white rounded-2 max500:w-[80vw]`}
          style={{
            backgroundColor: InstitutionData.LightPrimaryColor,
          }}
          onClick={onSubmit}
        >
          Sign Up
        </button>
        <p className={`text-[0.8rem] text-black text-center w-[19.5rem]`}>
          *By creating an account you agree to our{' '}
          <span
            className={`cursor-pointer`}
            onClick={() => {
              Navigate('/terms');
            }}
            style={{
              color: InstitutionData.LightPrimaryColor,
            }}
          >
            Terms of Use
          </span>{' '}
          as well as{' '}
          <span
            className={`cursor-pointer`}
            onClick={() => {
              Navigate('/privacypolicy');
            }}
            style={{
              color: InstitutionData.LightPrimaryColor,
            }}
          >
            Privacy Policy
          </span>
        </p>
        <p className={`text-[0.85rem] text-black cursor-pointer`} onClick={handleSignInRedirect}>
          Already logged In ?{' '}
          <span className={`font-[500]`} style={{ color: InstitutionData.LightPrimaryColor }}>
            Sign In
          </span>
        </p>
      </form>
    );
  }

  const form2 = () => {
    return (
      <form
        className={`w-[50vw] max800:w-[90vw] max-w-[35rem] bg-[#FFFFFF] shadow-2xl rounded-2xl p-4 flex flex-col items-center `}
      >
        <h3 className={`text-[1.2rem] font-roboto font-bold`}>Sign Up</h3>
        <ul className={`flex flex-col items-center`}>
          <li
            className={`flex items-center gap-20 mt-8 max500:flex-col max500:gap-2 max500:items-start`}
          >
            <label className={`w-20 max500:ml-3`}>OTP Code</label>
            <ValidatorForm onSubmit={onConfirmationSubmit}>
              <TextValidator
                label={
                  <span style={{ color: InstitutionData.LightPrimaryColor }}>
                    Enter 6 Digit OTP
                  </span>
                }
                variant="outlined"
                inputProps={{ maxLength: 6 }}
                name="otp"
                size="small"
                type="text"
                fullWidth
                validators={['required']}
                errorMessages={['OTP is required']}
                value={confirmationCode === 0 ? '' : confirmationCode}
                onChange={(e) => {
                  setConfirmationCode(e.target.value)
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onConfirmationSubmit(e);
                  }
                }}
              />
            </ValidatorForm>
          </li>
          {resendVisible ? (
            <button className={`mt-[1rem] ml-[5rem]`} onClick={onSubmit}>
              Resend OTP
            </button>
          ) : (
            <p className={`mt-[1rem]`}>
              Resend OTP in{' '}
              <span
                className={`font-bold`}
                style={{
                  color: InstitutionData.LightPrimaryColor
                }}
              >
                {counter}
              </span>{' '}
              seconds
            </p>
          )}
        </ul>
        {err && <p className={`text-[0.8rem] mt-2 text-red-500`}>{err}</p>}
        <p className={`text-center w-[80%] text-[0.81rem]`}>
          <strong className={`text-red-500`}>Note*</strong>
          {data.Otp_Msg}
        </p>
        <button
          className={`p-4 py-1 mt-6 mb-3 text-white rounded-lg`}
          style={{
            backgroundColor: InstitutionData.LightPrimaryColor
          }}
          onClick={onConfirmationSubmit}
        >
          Confirm code
        </button>
      </form>
    )
  }

  return (
    <div className={`w-screen min-h-screen`}>
      <NavBar />
      <div className={`flex flex-col items-center text-black`}>
        <div className={`flex w-[100%] gap-16 justify-center items-end mt-[8rem] `}>
          {!newUser ? form1() : form2()}
        </div>
      </div>
    </div>
  )
}

export default SignUp