import React from 'react'
import { Route, Routes } from 'react-router-dom'
import DashBoard from './pages/DashBoard'
import Home from './pages/Home'
import Logout from './pages/Logout'
import ErrorPage from './pages/Error'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import About from './pages/AboutUs'
import Instructor from './pages/Instructor'
import Query from './pages/Query'
import Subscription from './pages/Subscription'
import ForgotPassword from './pages/ForgotPassword'
import PaymentFailed from './pages/PaymentFailed'
import PaymentSuccessful from './pages/PaymentSuccessful'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Terms from './pages/Terms'
import Refund from './pages/Refund'
import ProfileUpdate from './Components/DashBoard/ProfileUpdate'
import HomePayment from "./CommonPayment/HomePayment";
import UserProfile from './Components/DashBoard/UserProfile'
import HybridClass from './pages/HybridClass'
import SubscriptionPopup from './pages/SubscriptionPopup'
import Meeting from './pages/Meeting'
import Rating from './pages/Rating'
import Pay from './pages/Pay'
import Gallery from './pages/Gallery'
import Auth from './pages/Auth'
// import Schedule from './pages/Schedule'
import Attendance from './pages/Attendance'
import HybridPayment from './pages/HybridPayment'
import NewSchedule from './pages/NewSchedule'

const RoutesContainer = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/pay/:institutionId/:productId/:cognitoId"
        element={<Pay />}
      />
      <Route path="/dashboard" element={<DashBoard />} />
      <Route path="/aboutus" element={<About />} />
      <Route path="/instructor" element={<Instructor />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/query" element={<Query />} />
      <Route path="/subscription" element={<Subscription />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/paymentsuccessful" element={<PaymentSuccessful />} />
      <Route path="/paymentfailed" element={<PaymentFailed />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/refund" element={<Refund />} />
      <Route path="*" element={<ErrorPage />} />
      <Route path="/settings" element={<ProfileUpdate />} />
      <Route path="/userProfile" element={<UserProfile />} />
      <Route path="/hybrid" element={<HybridClass />} />
      <Route path="/subscribe" element={<SubscriptionPopup />} />
      <Route path="/meeting" element={<Meeting />} />
      <Route path="/rating" element={<Rating />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/qr" element={<Attendance />} />
      <Route path="/schedule" element={<NewSchedule />} />
      <Route path="/hybridpayment" element={<HybridPayment />} />
      <Route path="/allpayment/:institution/:cognitoId/:emailId" element={<HomePayment />} />
      <Route path="/allpayment/:institution" element={<HomePayment />} />
      <Route path="/auth" element={<Auth />} />
      
    </Routes>
  )
}

export default RoutesContainer
