import {useContext, useEffect, useState} from "react";
import {API} from "aws-amplify";
import { PaginatedTable } from "../../Common/DataTable";
import Context from "../../Context/Context";
import web from "../../Utils/Data/data.json";

const Payments = () => {
  const {userData, util} = useContext(Context);
  const [orderHistory, setOrderHistory] = useState([[]]);

  useEffect(() => {
    const fetchData = async () => {
      util.setLoader(true);
      try {
        const response = await API.get(
          'clients',
          `/getReciept/${web.InstitutionId}/${userData.cognitoId}`,
          {}
        );
        const data = response.payments
          .map((object, index) => [
            (new Date(object.paymentDate)).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }),
            response.profile.products[index].S,
            (new Date(object.renewDate)).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }),
            // 'hello',
            object.paymentMode,
            `${object.currency === 'USD' ? '$' : '₹'}${object.amount / 100}`
          ])
          // .flatMap(item => Array(6).fill(item));
        setOrderHistory(data);
      } catch (error) {
        console.error('Error fetching payment history:', error);
      } finally {
        util.setLoader(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='mx-8 p-8 bg-gray-100 font-family'>
      <h2 className='text-2xl font-bold'>Membership Details</h2>
      <div className='bg-white my-4 p-4'>
        <p className='bg-lightPrimaryColor text-white font-bold w-fit -ml-6 my-2 py-2 px-4 rounded-r-full'>Member
          since {(new Date(userData.joiningDate).toLocaleString('default', {month: 'long'}))} {(new Date(userData.joiningDate).getFullYear())}</p>
        <p className='text-xl font-bold'>Plan - <span className='text-lg font-normal'>{orderHistory.slice(-1)[0][1]}</span></p>
        <p className='text-xl font-bold'>Amount - <span className='text-lg font-normal'>{orderHistory.slice(-1)[0][4]}</span></p>
        <p className='text-xl font-bold'>Mode of Payment - <span className='text-lg font-normal'>{orderHistory.slice(-1)[0][3]}</span></p>
        <p className='text-xl font-bold'>Next Billing Date - <span className='text-lg font-normal'>{orderHistory.slice(-1)[0][2]}</span></p>
      </div>

      <h2 className='text-2xl font-bold mt-16'>Billing History</h2>
      <div>
        <PaginatedTable
          head={['Date', 'Description', 'End On', 'Payment Method', 'Amount']}
          data={orderHistory}
          itemsPerPage={5}
        />
      </div>
    </div>
  )

  // return (
  //   <div className='mt-4 mx-4'>
  //     <h1 className='text-4xl font-bold'>Billing</h1>
  //
  //     <div className='flex flex-row flex-wrap justify-center items-center'>
  //       <div style={{backgroundColor: PrimaryColor}} className='w-max-xl rounded-3xl p-4 mt-8 mx-4 text-white'>
  //         <h2>Your Subscription</h2>
  //         <p className='text-xl font-bold'>{userData.products.slice(-1)[0].S}</p>
  //         <br/>
  //         <p>Renews on {(new Date(userData.renewDate)).toDateString()}</p>
  //       </div>
  //
  //       <div style={{backgroundColor: PrimaryColor}} className='w-max-md rounded-3xl p-4 mt-8 mx-4 text-white'>
  //         <h2>You are currently paying:</h2>
  //         <p className='text-xl font-bold'>{amount}</p>
  //         <br/>
  //         <p>Click here to cancel.</p>
  //       </div>
  //     </div>
  //
  //     <div className='max-w-4xl mt-16 mx-auto'>
  //       <h2 className='text-2xl font-bold'>Your Order History:</h2>
  //       <PaginatedTable
  //           head={['Date', 'Description', 'Payment Method', 'Amount']}
  //           data={orderHistory}
  //       />
  //     </div>
  //
  //   </div>
  // )
}

export default Payments;