import React, { useContext } from 'react'
import NavBar from '../Components/NavBar'
import Header from '../Components/Home/Header'
import Header3 from '../Components/Home/Header3'
import Testimonial from '../Components/Home/Testimonial'
import FAQ from '../Components/Home/FAQ'
import Footer from '../Components/Home/Footer'
import Subscription from '../Components/Home/Subscription'
import Context from '../Context/Context'

function Home() {

  const UserCtx = useContext(Context);

  return (
    <div className={` overflow-hidden`}>
      <NavBar />

      <Header />

      <Header3 />

      <Testimonial />

      {!UserCtx.isAuth || UserCtx.userData.userType !== 'admin' ? <Subscription /> : null}

      <FAQ />

      <Footer />
    </div>
  )
}

export default Home
